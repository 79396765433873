
import Inverter from 'src/sli/Inverter'

class InvertersList {

    static list = [];

    static addNewInverterAndConnect(plantUUID, name) {

        var newInverter;

        newInverter = this.getInverterByName(name);

        if(newInverter !== null) {
            return newInverter;
        }

        newInverter = new Inverter(plantUUID, name);
        this.list[name] = newInverter;

        newInverter.connect();

        return newInverter;
    }

    static removeInverterAndDisconnect(name) {

        if (typeof this.list[name] === 'undefined' || this.list[name] === null) {
            return;
        }

        let inverter = this.list[name];

        try {
            inverter.disconnect();
        } catch (e) {

        }
        this.list[name] = null;
    }

    static destroyList() {
        console.log("Destroy inverter list");

        for (let key in this.list) {
            let value = this.list[key];
            this.removeInverterAndDisconnect(key);
        }
    }

    static getInverterByName(name) {
        if (typeof this.list[name] !== 'undefined' && this.list[name] !== null) {
            return this.list[name];
        } else {
            return null;
        }
    }


}

export default InvertersList;