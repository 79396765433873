import React from 'react'

import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut, AmplifySignUp, AmplifyConfirmSignIn, AmplifyConfirmSignUp, AmplifyForgotPassword, AmplifyRequireNewPassword, AmplifyVerifyContact, AmplifyGreetings, AmplifyLoadingSpinner, AmplifyContainer, AmplifyAuthContainer } from '@aws-amplify/ui-react';

import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

const CustomAuth = () => {
    return (
        <AmplifyAuthContainer>
            <AmplifyAuthenticator>
                <AmplifySignUp
                    slot="sign-up"
                    formFields={[
                        {
                            type: "username",
                            placeholder: "Enter your username",
                            inputProps: { required: true},
                        },
                        {
                            type: "password",
                            name: "password",
                            placeholder: "Enter your password",
                            inputProps: { required: true, autocomplete: "new-password" },
                        },
                        {
                            type: "email",
                            placeholder: "Enter your email",
                            inputProps: { required: true},
                        },
                        {
                            type: 'custom:Device_ID',
                            label: "Serial Number of Inverter",
                            placeholder: "Enter serial number inverter",
                            inputProps: { required: true},
                        },
                    ]}
                />
                <AmplifySignIn
                    slot="sign-in"
                    usernameAlias='username'
                    formFields={[
                        {
                            type: "username",
                            placeholder: "Enter your username",
                            name: "username",
                            inputProps: { required: true, autocomplete: "username" },
                        },
                        {
                            type: "password",
                            placeholder: "Enter your password",
                            name: "password",
                            inputProps: { required: true, autocomplete: "current-password" },
                        },
                    ]}
                />
                <AmplifyForgotPassword 
                    slot="forgot-password"
                    formFields={[
                        {
                            type: "username",
                            placeholder: "Enter your username",
                            inputProps: { required: true, autocomplete: "username"},
                        },
                    ]}
                />
            </AmplifyAuthenticator >
        </AmplifyAuthContainer>
    )
}

export default CustomAuth