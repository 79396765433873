import * as Helper from 'src/sli/helper'

const PERIOD = {
    DAY: 'Day',
    MONTH: 'Month',
    YEAR: 'Year',
    HISTORY: 'History'
};

const DATA_TYPE = {
    INVERTER: 'Inverter',
    GRID_EXPORT: 'GridExport',
    GRID_IMPORT: 'GridImport',
    ACCU_LOAD: 'AccuLoad',
    ACCU_CHARGE: 'AccuCharge',
    HOT_WATER: 'HotWater'
};

class EnergyRecord {

    setDataTime = {};
    data = {};

}

class EnergyDataTypes {

    [DATA_TYPE.INVERTER] = new EnergyRecord();
    [DATA_TYPE.GRID_EXPORT] = new EnergyRecord();
    [DATA_TYPE.GRID_IMPORT] = new EnergyRecord();
    [DATA_TYPE.ACCU_LOAD] = new EnergyRecord();
    [DATA_TYPE.ACCU_CHARGE] = new EnergyRecord();
    [DATA_TYPE.HOT_WATER] = new EnergyRecord();

}

export default class EnergyStatistics {

    [PERIOD.DAY] = {};
    [PERIOD.MONTH] = {};
    [PERIOD.YEAR] = {};
    [PERIOD.HISTORY] = {};

    constructor() {
        this[PERIOD.DAY] = new EnergyDataTypes();
        this[PERIOD.MONTH] = new EnergyDataTypes();
        this[PERIOD.YEAR] = new EnergyDataTypes();
        this[PERIOD.HISTORY] = new EnergyDataTypes();
    }

    static getPeriodsArray = () => [PERIOD.DAY, PERIOD.MONTH, PERIOD.YEAR, PERIOD.HISTORY];

    /** Funkcja pomocnicza do pobierania daty z klucza w formacie RRRR lub RRRRMM lub RRRRRMMDD lub RRRRMMDDHH lub RRRRMMDDHHmm */
    static getDateFromDateKey(key) {

        let year;
        let month = 1;
        let day = 1;
        let hour = 0;
        let min = 0;

        if (key.length >= 4) {
            year = key.substring(0, 4);
        } else {
            return null;
        }

        if (key.length >= 6) {
            month = key.substring(4, 6);
            month -= 1;
        }

        if (key.length >= 8) {
            day = key.substring(6, 8);
        }

        if (key.length >= 10) {
            hour = key.substring(8, 10);
        }

        if (key.length >= 12) {
            min = key.substring(10, 12);
        }
        return new Date(year, month, day, hour, min);
    }

    /** Funkcja pomocnicza do pobierania czasu UnixTimestamp w sekundach dla podanego klucza */
    static getTimestampFromDateKey(key) {
        return EnergyStatistics.getDateFromDateKey(key).getTime()/1000;
    }

    /** Funkcja pomocnicza do sprawdzenia czy podany klucz dotyczy dnia dzisiejszego */
    static isDateKeyisToday(key) {
        return Helper.isToday(EnergyStatistics.getDateFromDateKey(key));
    }

    /** Funkcja pomocnicza do konwersji daty na klucz w formacie RRRRMMDDHHmm */
    static getDateKeyFromDate(date, periodType) {
        let year = date.getFullYear().toString().lpad("0", 4);
        let month = (date.getMonth() + 1).toString().lpad("0", 2);
        let day = date.getDate().toString().lpad("0", 2);
        let hour = date.getHours().toString().lpad("0", 2);
        let minutes = date.getMinutes().toString().lpad("0", 2);


        switch (periodType) {
            case PERIOD.DAY:
                return `${year}${month}${day}${hour}${minutes}`;

            case PERIOD.MONTH:
                return `${year}${month}${day}`;

            case PERIOD.YEAR:
                return `${year}${month}`;

            case PERIOD.HISTORY:
                return `${year}`;
        }

        return `${year}${month}${day}${hour}${minutes}`;

    }

    static isCurrentDateInPeriod = (date, periodType) => {
        switch (periodType) {
          case PERIOD.MONTH:
            return Helper.isCurrentMonth(date);
    
          case PERIOD.YEAR:
            return Helper.isCurrentYear(date);
    
          case PERIOD.HISTORY:
            return;
    
          case PERIOD.DAY:
            return Helper.isToday(date);
    
          default:
            return;
        }
      }


    static getFirstDateFromTimestamp(timestampSec, periodType) {
        let startDate = new Date((timestampSec) * 1000);

        switch (periodType) {
            case PERIOD.DAY:
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                break;

            case PERIOD.MONTH:
                startDate.setDate(1);
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                break;

            case PERIOD.YEAR:
                startDate.setMonth(0);
                startDate.setDate(1);
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                break;

            case PERIOD.HISTORY:
                startDate.setFullYear(2015);    //TODO docelowo 2020
                startDate.setMonth(0);
                startDate.setDate(1);
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                break;

        }

        return startDate;

    }

    static getLastDateFromTimestamp(timestampSec, periodType) {
        let currTime = new Date(timestampSec * 1000);

        switch (periodType) {
            case PERIOD.DAY:
                currTime.setHours(23);
                currTime.setMinutes(59);
                return currTime;

            case PERIOD.MONTH:
                return new Date(currTime.getFullYear(), currTime.getMonth() + 1, 0);

            case PERIOD.YEAR:
                currTime.setMonth(11);
                currTime.setDate(31);
                currTime.setHours(0);
                currTime.setMinutes(0);
                return currTime;

            case PERIOD.HISTORY:
                return new Date();
        }
    }

    static getLastKeyFromKeyTime(dateKey, periodType) {
        let date = EnergyStatistics.getDateFromDateKey(dateKey);
        let lastDate = EnergyStatistics.getLastDateFromTimestamp(date.getTime() / 1000, periodType);

        return EnergyStatistics.getDateKeyFromDate(lastDate, periodType);

    }


    setData(period, dataType, timestampKey, data) {
        this[period][dataType].setDataTime[timestampKey] = 1;
        this[period][dataType].data[timestampKey] = data.slice();
    }


    getData(period, dataType, timestampKey) {

        if (this[period][dataType].setDataTime[timestampKey]) {
            console.log(this[period][dataType].data[timestampKey]);
            return this[period][dataType].data[timestampKey];
        } else {
            console.log("NULL!");
            return null;
        }
    }


    // setDaily(timestamp_sec, data) {
    //     this[PERIOD.DAY][DATA_TYPE.INVERTER][timestamp_sec] = data.slice();
    // }

    // getDaily(timestamp_sec) {
    //     return this[PERIOD.DAY][DATA_TYPE.INVERTER][timestamp_sec];
    // }

    // setMonthly(timestamp_sec, data) {
    //     this[PERIOD.MONTH][timestamp_sec] = data.slice();
    // }

    // setYearly(timestamp_sec, data) {
    //     this[PERIOD.YEAR][timestamp_sec] = data.slice();
    // }

    // setHistorical(timestamp_sec, data) {
    //     this[PERIOD.HISTORY][timestamp_sec] = data.slice();
    // }
}

EnergyStatistics.PERIOD = PERIOD;
EnergyStatistics.DATA_TYPE = DATA_TYPE;