
import { PubSub, API, Auth } from 'aws-amplify';
import AppUser from 'src/sli/AppUser';
import * as Helper from 'src/sli/helper'
import InverterMsg from './InverterMsg';
import SevAPI from './SevAPI';
import EnergyStatistics from './EnergyStatistics'

class Inverter {

    isConnected = false;
    name = '';
    subscribe = null;
    observers = null;
    messagesQueue = null;
    getTodayEnergyDataRequestTimestamp = 0;
    plantUUID = ''

    //TODO API - ver ? chodzi o MQTT - np. ramki moga miec inne parametry ? Nalezy miec to na uwadze

    constructor(plantUUID, name) {
        console.log(`Tworze inwerter: ${name}`);
        this.name = name;
        this.plantUUID = plantUUID;
        this.observers = new Array();
        this.messagesQueue = new Array();
        this._subscribeResponse();
        this.userConnected();

        this.energyStatistics = new EnergyStatistics();

    }

    _notifyObserver(observer, data) {
        if (observer !== null && typeof observer !== 'undefined') {
            if (typeof observer.dataNotify === 'function') {
                observer.dataNotify(data);
            }
        }
    }

    _notifyObservers(data) {
        if (this.observers && this.observers.length > 0) {
            for (let index = 0; index < this.observers.length; ++index) {
                let observer = this.observers[index];
                this._notifyObserver(observer, data);
            }
        }
    }

    _handleReceivedResponse(data) {

        let payload = data.value;


        console.log("_handleReceivedResponse");
        console.log(payload);

        /** Jesli jest to potwierdzenie na wyslana wiadomosc to usuwam ja z kolejki wyslanych i informuje tych, ktorzy wyslali zapytanie */
        if (payload.rspMsgID !== 'undefined') {
            let msg = this.findMessageByID(payload.rspMsgID);
            if (msg !== null && msg !== 'undefined' && typeof msg !== 'undefined') {
                msg.receivedACK(payload);
                this.removeMessage(msg);
                return;
            }
        }
        /** Jesli to nie odpowiedz, a informacja niezalezna to przekazuje wiadomosc do "obserwujacych" */
        this._notifyObservers(payload)
    }

    _subscribeResponse() {

        if (this.subscribe !== null) {
            return;
        }

        let topic = AppUser.getTopicNameWebRsp();

        this.subscribe = PubSub.subscribe(topic).subscribe({
            next: (data) => {
                this._handleReceivedResponse(data)
            },
            error: error => console.log('_subscribeResponse ERROR!!!!!!!!' + JSON.stringify(error)),
            close: () => console.log('_subscribeResponse Done'),
        });

        /* Subksrybcja stanow polaczenia urzadzenia ? TODO ?
                let tstconn = PubSub.subscribe('$aws/events/presence/connected/'+this.name).subscribe({
                    next: (data) => {
                        console.log(this.name+' connected');
                        console.log(data)
                    },
                    error: error => console.log('tstconn ERROR!!!!!!!!' + JSON.stringify(error)),
                    close: () => console.log('tstconn Done'),
                });
        
                let tstdisconn = PubSub.subscribe('$aws/events/presence/disconnected/'+this.name).subscribe({
                    next: (data) => {
                        console.log(this.name+' disconnected');
                        console.log(data)
                    },
                    error: error => console.log('tstdisconn ERROR!!!!!!!!' + JSON.stringify(error)),
                    close: () => console.log('tstdisconn Done'),
                });
        */

    }

    /** Moze przy wylogowaniu ? */
    _unsubscribeResponse() {

        if (this.subscribe === null) {
            return;
        }

        this.observers = [];
        this.subscribe.unsubscribe();
        this.subscribe = null;

    }

    addObserver(observer) {
        console.log('addObserver');
        this.observers.push(observer);
        console.log(this.observers);
    }

    removeObserver(observer) {
        console.log('removeObserver');
        Helper.removeItemAll(this.observers, observer);
    }


    addMessage(msg) {
        console.log('addMessage');
        console.log(msg);
        this.messagesQueue.push(msg);
        console.log(this.messagesQueue);
    }

    removeMessage(msg) {
        console.log('removeMessage');
        Helper.removeItemAll(this.messagesQueue, msg);
        console.log(this.messagesQueue);
    }

    findMessageByID(msgID) {
        return this.messagesQueue.find(msg => msg.getID() == msgID);
    }

    removeMessageByID(msgID) {
        let msgToRemove = this.findMessageByID(msgID);
        if (msgToRemove !== 'undefined') {
            this.removeMessage(msgToRemove);
        }
    }

    requestConfig(cbResponse, cbTimeout) {
        console.log("sendConfigREQ to device: " + this.name);
        let msg = new InverterMsg(InverterMsg.TYPE_CONFIG_REQ, cbResponse, cbTimeout);
        this.addMessage(msg);
        msg.send(this, null, this.removeMessage.bind(this));
    }

    sendConfig(config, cbResponse, cbTimeout) {
        console.log("sendConfig to device: " + this.name);
        let msg = new InverterMsg(InverterMsg.TYPE_CONFIG_SET, cbResponse, cbTimeout);
        this.addMessage(msg);
        msg.send(this, config, this.removeMessage.bind(this));
    }

    userConnected() {

        let username = AppUser.getUsername();
        let env = AppUser.getBackendEnv();
        let topic = `$aws/rules/SevSendMsgRule_${env}/sev/${username}/${this.plantUUID}/${this.name}/userConn`;

        let payload = {
            payload: JSON.stringify({
                userName: username
            })
        }

        if (topic.length > 0) {
            PubSub.publish(topic, payload);
        }

    }

    connect() {

        if (this.isConnected) {
            return;
        }

        console.log('connect');
        //TODO tutaj wywolac API, ktore wysle do urzadzenia NewUser ?
        this.isConnected = true;
    }

    disconnect() {
        if (this.isConnected == false) {
            return;
        }

        this._unsubscribeResponse();
        //TODO tutaj wywolac API, ktore wysle do urzadzenia usuniecie zalgoowanego uzytkownika ?

        console.log('disconnect');
        this.isConnected = false;
    }

    async getConfigFromDB(cbResult) {
        SevAPI.getInverterConfigFromDBPromise(this.plantUUID, this.name).then((result) => {
            try {
                cbResult(result.inverter.inv_conf);
            } catch (e) {
    
            }
        })
    };

    async updateEnergyDataFromDB(from, to, period, dataType, cbResult) {

        await SevAPI.getInverterEnergyDataFromDBPromise(this.plantUUID, this.name, from, to, period, dataType).then((result) => {
            try {
                this.energyStatistics.setData(period, dataType, from, result.energyStats[dataType]);
                if (cbResult !== null) {
                    cbResult(result.energyStats[dataType]);
                }
    
                if (EnergyStatistics.isDateKeyisToday(from)) {
                    this.getTodayEnergyDataRequestTimestamp = new Date().getTime() / 1000;
                }
    
            } catch (e) {
                console.log("ERR?");
                console.log(e);
            }
        });
    }

    getEnergyData(period, dataType, timestampKey, cbResult) {

        //console.log("getEnergyData: " + period + " key" + timestampKey);

        let currentData = this.energyStatistics.getData(period, dataType, timestampKey);

        if (currentData && EnergyStatistics.isDateKeyisToday(timestampKey) && (Math.abs(((new Date().getTime()) / 1000) - this.getTodayEnergyDataRequestTimestamp) > 120)) {
            currentData = null;
        }

        if (currentData) {
            console.log("Mam dane - wywoluje Cb");

            //moze jeszcze zapamietac czas odpytania i jesli dotyczy to dzisiejszego dnia i bylo juz jakis czas temu odpytywane to wtedy odpytac

            if (cbResult !== null) {
                cbResult(currentData);
            }

            return currentData;

        } else {
            console.log("Nie mam danych - odpytuje Api");

            let toKey = EnergyStatistics.getLastKeyFromKeyTime(timestampKey, period);

            this.updateEnergyDataFromDB(timestampKey, toKey, period, dataType, cbResult); //TODO dodaj do timestamp jakas wartosc!
            return this.energyStatistics.getData(period, dataType, timestampKey);
        }
    }
}

export default Inverter;