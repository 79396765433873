import React, { createContext } from "react";

const PlantContext = createContext({
    selectedOption: {value: '', label: ''},
    setSelectedOption: () => { },
    plantsSelectOptions: [{}],
    setPlantsSelectOptions: () => { },
    removePlantByUID: () => { },
    addPlant: () => { },
    changePlantName: () => {},
     
});

export default PlantContext;