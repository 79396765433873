import { API, Auth, PubSub } from 'aws-amplify';
import InvertersList from 'src/sli/InvertersList'
import SevAPI from './SevAPI';

import awsmobile from '../aws-exports.js';

/** Hack to get amplify env */
const restEndpoint = awsmobile.aws_dynamodb_table_schemas[0].tableName;

class AppUser {

    static groups = null;
    static username = '';
    static isInit = false;

    static async init() {
        if (this.isInit === true) {
            return;
        }

        let cred = await Auth.currentCredentials();

        Auth.currentSession().then((session) => {
            this.groups = session.idToken.payload['cognito:groups'];
            this.username = session.idToken.payload['cognito:username'];
            SevAPI.userLoggedPromise(this.username, session.getAccessToken().getJwtToken(), cred.identityId).then((session) => {
                this.isInit = true;
            });
        });
    }

    static async deinit() {

        if (this.isInit === false) {
            return;
        }

        this.groups = null;
        this.username = '';
        this.isInit = false;

        InvertersList.destroyList();

    }

    static isBelongToGroup(group) {

        var found = false;

        if (this.groups) {
            found = this.groups.some(r => group.indexOf(r) >= 0);
        }
        return found;
    }

    static isAdmin() {
        return this.isBelongToGroup('admin');
    }

    static isUser() {
        return this.isBelongToGroup('user');
    }

    static isService() {
        return this.isBelongToGroup('service');
    }

    static getUsername() {
        return this.username;
    }

    static getTopicNameWebRsp() {
        return `cmd/sev/usr/${this.username}/webRsp`;
    }

    static getBackendEnv() {
        return restEndpoint.split('-')[1];
    }

}

export default AppUser;
