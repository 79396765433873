import React, { Component, useEffect, useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import CustomAuth from './views/pages/CustomAuth';

import { I18n } from '@aws-amplify/core';
import * as translationsPL from './lang/pl.json';
import * as translationsEN from './lang/en.json';
import AppUser from './sli/AppUser'
import PlantContext from './PlantContext';

import { NAV_INVERTER, NAV_PLANT, NAV_HOME, SUBHEADER_PLANTS } from 'src/consts'

I18n.putVocabulariesForLanguage('en', translationsEN);
I18n.putVocabulariesForLanguage('pl', translationsPL);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {

  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  const [selectedOption, setSelectedOption] = useState({ value: '', label: '' });
  const [plantsSelectOptions, setPlantsSelectOptions] = useState([{}]);

  const removePlantByUID = (uid) => {
    setPlantsSelectOptions(plantsSelectOptions.filter(opt => opt.value !== uid));
    setSelectedOption(null);
  }
  const addPlant = (uid, name) => {
    var newArray = plantsSelectOptions.slice();
    newArray.push({ value: uid, label: name });
    setPlantsSelectOptions(newArray);
    setSelectedOption({ value: uid, label: name });
  }

  const changePlantName = (uid, newName) => {
    var newArray = plantsSelectOptions.slice();
    let objIndex = newArray.findIndex((obj => obj.value === uid));

    if(objIndex < 0) {
      return;
    }
    newArray[objIndex].label = newName;

    setPlantsSelectOptions(newArray);
    setSelectedOption({ value: uid, label: newName });
  }

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);

      if (authData && nextAuthState === AuthState.SignedIn) {
        console.log("grupy:");
        console.log(authData.signInUserSession.idToken.payload['cognito:groups']);
        AppUser.init();
      } else if (nextAuthState === AuthState.SignedOut) {
        AppUser.deinit();
      }
    });
  }, []);


  return (authState === AuthState.SignedIn) && user ? (
    <PlantContext.Provider value={{ selectedOption, setSelectedOption, plantsSelectOptions, setPlantsSelectOptions, removePlantByUID, addPlant, changePlantName }}>

      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route path="/plants" name="Home" render={props => <TheLayout subheader={SUBHEADER_PLANTS}{...props} />} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>

    </PlantContext.Provider >
  ) : (
    <CustomAuth></CustomAuth>
  );
}

export default App;
