/** Rodzaje bocznego menu */
export const NAV_HOME = 'NavHome';
export const NAV_INVERTER = 'NavInverter';
export const NAV_PLANT = 'NavPlant';

/** Rodzaje podnaglowkow */
export const SUBHEADER_NULL = 0;
export const SUBHEADER_PLANTS = 1;



/* Statusy fomularza PlantCreate */
export const PLANT_CREATE_IDLE = 'PLANT_CREATE_IDLE';
export const PLANT_CREATE_PENDING = 'PLANT_CREATE_PENDING';
export const PLANT_CREATE_SUCCESS = 'PLANT_CREATE_SUCCESS';
export const PLANT_CREATE_RESET = 'PLANT_CREATE_RESET';
export const PLANT_CREATE_ERROR = 'PLANT_CREATE_ERROR';
export const PLANT_UPDATE_SUCCESS = 'PLANT_UPDATE_SUCCESS';
export const PLANT_UPDATE_ERROR = 'PLANT_UPDATE_RESET';


/* Statusy fomularza dodawania inwertera do instalacji */
export const ADD_INV2PLANT_IDLE = 'ADD_INV2PLANT_IDLE';
export const ADD_INV2PLANT_PENDING = 'ADD_INV2PLANT_PENDING';
export const ADD_INV2PLANT_SUCCESS = 'ADD_INV2PLANT_SUCCESS';
export const ADD_INV2PLANT_ERROR = 'ADD_INV2PLANT_ERROR';

/* Statusy fomularza dodawania uzytkownika do instalacji */
export const ADD_USER2PLANT_IDLE = 'ADD_USER2PLANT_IDLE';
export const ADD_USER2PLANT_PENDING = 'ADD_USER2PLANT_PENDING';
export const ADD_USER2PLANT_SUCCESS = 'ADD_USER2PLANT_SUCCESS';
export const ADD_USER2PLANT_ERROR = 'ADD_USER2PLANT_ERROR';


/** Statusy formularza konfiguracji inwertera */
export const STATUS_CONF_NULL = 'CONF_NULL';
export const STATUS_CONF_PROGRESS = 'CONF_PROGRESS';
export const STATUS_CONF_TIMEOUT = 'CONF_TIMEOUT';
export const STATUS_CONF_OK = 'CONF_OK';


/** Statusy obslugi formularza */
export const FORM_STATE_NULL = 0;
export const FORM_STATE_ERROR = 1;
export const FORM_STATE_IDLE = 2;
export const FORM_STATE_PENDING = 3;
export const FORM_STATE_SUCCESS = 4;
export const FORM_STATE_RESET = 5;
export const FORM_STATE_TIMEOUT = 6;
