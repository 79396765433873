import { PubSub } from "aws-amplify";
import AppUser from "./AppUser";

const TYPE_NULL = 'NULL';
const TYPE_CONFIG_SET = 'CONFIG_SET';
const TYPE_CONFIG_REQ = 'CONFIG_REQ';
const TYPE_CONFIG = 'CONFIG';
const TYPE_NEXT = 'NEXT';


class InverterMsg {

    static nextID = 0;

    timer = null;
    type = TYPE_NULL;
    ID = 0;
    responseID = 0;
    rawData = null;

    cbResponse = null;
    cbTimeout = null;

    constructor(type, cbResponse, cbTimeout) {
        this.type = type;
        this.cbResponse = cbResponse;
        this.cbTimeout = cbTimeout;
    }

    send(inverterObj, data, timeoutCb) {
        this.timer = setTimeout(function () {
            this.cbTimeout();
            timeoutCb(this);
            this.timer = null;
        }.bind(this), 3000);

        this.ID = ++InverterMsg.nextID;

        let payload = {
            username: AppUser.getUsername(),
            //msgType: this.type, ?? COMMAND?
            msgID: this.ID,
            responseTo: AppUser.getTopicNameWebRsp(),
            cmd: {},

            //TODO ?
            //groups: ['admin', 'service', 'user'],
            //userID: 3q42kj23c423jkh4ck2j3h4c
            //resposeTo: /cmd/sev/$clientId/webRsp
            //TODO version?
        }

        let env = AppUser.getBackendEnv();

        let topic = `$aws/rules/SevSendMsgRule_${env}/sev/${payload.username}/${inverterObj.plantUUID}/${inverterObj.name}/userCmd`;

        switch (this.type) {
            case TYPE_CONFIG_SET:
                payload.cmd = { [this.type]: { data } };
                break;

            case TYPE_CONFIG_REQ:
                payload.cmd = { [this.type]: {} };
                break;

            default:
                console.log("Nieznany typ:" + this.type);
                this.topic = '';
                break;
        }

        if (topic.length > 0) {
            console.log(`Publish to topic: ${topic}`);
            PubSub.publish(topic, payload);
        }
    }

    receivedACK(payload) {
        if (this.timer !== 'undefined' && this.timer !== null) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.cbResponse(payload);
    }

    getID() {
        return this.ID;
    }

    getType() {
        return this.type;
    }

    static get TYPE_CONFIG_SET() {
        return TYPE_CONFIG_SET;
    }

    static get TYPE_CONFIG_REQ() {
        return TYPE_CONFIG_REQ;
    }


    static get TYPE_NEXT() {
        return TYPE_NEXT;
    }
}

export default InverterMsg;