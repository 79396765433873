import { API, Auth } from "aws-amplify";
import EnergyStatistics from "./EnergyStatistics";

const apiName = 'SevApi';


async function getUserToken() {
    let session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
}


class SevAPI {

    static async getInverterConfigFromDBPromise(plantUUID, invName) {

        const path = `/v1/plants/${plantUUID}/inverters/${invName}/config`;

        let token = await getUserToken();

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                //    'Authorization': `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                token: token
            },
        }
        return API.get(apiName, path, myInit);
    }

    /** Inform inverter about logged user */
    static userLoggedPromise(username, token, identityID) {

        const path = `/v1/user/${username}/logged`;

        //let token = (await Auth.currentSession()).getAccessToken().getJwtToken();

        const myInit = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: token
            },
            body: {
                token: token,
                identityID: identityID
            }
        }

        return API.post(apiName, path, myInit);
    }


    static async getInvertersPromise(plantUUID) {

        let path = `/v1/plants/${plantUUID}/inverters`;

        let token = await getUserToken();

        Auth.currentCredentials().then((info) => {
        });


        let myInit = {
            queryStringParameters: {
                token: token
            },
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return API.get(apiName, path, myInit);

    }

    static async getUsersPromise(plantUUID) {

        let path = `/v1/plants/${plantUUID}/users`;

        let token = await getUserToken();

        Auth.currentCredentials().then((info) => {
        });


        let myInit = {
            queryStringParameters: {
                token: token
            },
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return API.get(apiName, path, myInit);

    }

    static async addUserToPlantPromise(plantUUID, userName) {

        const path = `/v1/plants/${plantUUID}/users`;

        let token = await getUserToken();

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                token: token,
                userName: userName
            },
        }
        return API.post(apiName, path, myInit);
    }


    static async getInverterEnergyDataFromDBPromise(plantUUID, invName, from, to, periodType = EnergyStatistics.PERIOD.DAY, dataType = 'Inverter') {

        const path = `/v1/plants/${plantUUID}/inverters/${invName}/energy`;

        let token = await getUserToken();

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            queryStringParameters: {
                from: from,
                to: to,
                periodType: periodType,
                dataType: dataType,
                token: token
            },
        }
        return API.get(apiName, path, myInit);
    }

    static async createPlantPromise(parameters) {

        const path = '/v1/plants/';

        let token = await getUserToken();

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                token: token,
                parameters: parameters
            },
        }
        return API.post(apiName, path, myInit);

    }


    static async updatePlantPromise(plantUUID, parameters) {

        const path = `/v1/plants/${plantUUID}`;

        let token = await getUserToken();

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                token: token,
                parameters: parameters
            },
        }
        return API.put(apiName, path, myInit);

    }

    static async removePlantPromise(plantUUID) {

        const path = `/v1/plants/${plantUUID}`;

        let token = await getUserToken();

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                token: token
            },
        }
        return API.del(apiName, path, myInit);
    }

    static async getPlantPromise(plantUUID) {

        let path = `/v1/plants/${plantUUID}`;

        let token = await getUserToken();

        Auth.currentCredentials().then((info) => {
        });


        let myInit = {
            queryStringParameters: {
                token: token
            },
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return API.get(apiName, path, myInit);
    }

    static async getPlantsPromise() {

        let path = '/v1/plants';

        let token = await getUserToken();

        Auth.currentCredentials().then((info) => {
        });

        let myInit = {
            queryStringParameters: {
                token: token
            },
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return API.get(apiName, path, myInit);

    }

    static async addInverterToPlantPromise(plantUUID, inverterName) {

        const path = `/v1/plants/${plantUUID}/inverters`;

        let token = await getUserToken();

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                token: token,
                inverterName: inverterName
            },
        }
        return API.post(apiName, path, myInit);
    }
}

export default SevAPI;